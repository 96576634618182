// Please write exact color name 
:root{
    --main-color: #BDA4FF;
    --heading: #2F3032;
    --green-color:#B8BF7C;
    --gray-color:#CBCBCB;
    --subheading: #818181;
}

/////////////////////////////////////////////////////////////////
///////////// Follow the same color name below//////////////////
///////////////////////////////////////////////////////////////

// These variables with $ sign will be used in all other style file
// No color code will be write in any style file directly

$main-color: var(--main-color);
$heading-color: var(--heading);
$green-color: var(--green-color);
$gray-color: var(--gray-color);
$subHeading-color : var(--subheading);