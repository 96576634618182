.checkbox-input {
    &.p-checkbox .p-checkbox-input {
        border-radius: 50%;
    }
    &.p-checkbox .p-checkbox-box {
        border-radius: 50%;
        background: $gray-color;
    }
}
.otp-box {
    width: 63px !important;
    height: 72px !important;
    border-radius: 15px;
}

input[type="checkbox"].checkbox-select {
    accent-color: #fff;
    width: 20px;
    height: 20px;
}
@media screen and (max-width: 991px) {
    input[type="checkbox"].checkbox-select {
        // accent-color: #000;
        filter: grayscale(2);
    }
}
