.plan-box {
    border: 1px solid #cbcbcb;
    padding: 1.5rem;

    .check-active {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.login-card {
    width: 571px;
    border-radius: 20px;
}
.header-none {
    .p-dialog-header {
        display: none;
    }
    .p-dialog-content {
        padding: 20px;
        border-radius: 10px;
    }
}
.checkbox-select {
    &.p-checkbox.p-highlight .p-checkbox-box {
        background-color: #fff;
        border-color: #fff;
        svg {
            color: $main-color;
        }
    }
}
@media screen and (max-width: 576px) {
    .plan-box {
        padding: 1rem;

        .check-active {
            width: 21px;
            height: 21px;
        }
    }
    .login-card {
        width: 95%;
    }
}
