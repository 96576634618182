@import "./assets/styles/all.scss";
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

body {
    margin: 0;
    padding: 0;
    font-family: "General Sans", sans-serif;
}

.nunito {
    font-family: "Nunito", sans-serif;
    font-style: normal;
}

.sub-heading-size {
    font-size: 30px;
}

.heading-size {
    font-size: 36px !important;
}
@media screen and (max-width: 576px) {
    .sub-heading-size {
        font-size: 24px;
    }

    .heading-size {
        font-size: 24px !important;
    }
}
// .footer {
//   position: absolute;
//   bottom: -70px;
// }

.loader {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    animation: moveLoader 1s linear infinite;
    border-color: #bcddfc #5f9dfa #0084ff #a6d1fa;
    margin-left: 4px;
}
.button-width {
    height: 38px;
}

@keyframes moveLoader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
