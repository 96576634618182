.main-btn{
    min-width: 113px;
    border-color: $main-color;
    box-shadow: none !important;
    outline: none !important;
}
.btn-outline{
    color: $main-color;
    background: transparent;
    
}
@media screen and (max-width:576px) {
    .main-btn{
        min-width: 100px;
    }
}
