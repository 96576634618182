.signup-divider {
    &::after {
        content: "";
        width: 33%;
        height: 1px;
        background: #E0E0E0;
        position: absolute;
        right: 0;
        top: 11px;
    }
    &::before {
        content: "";
        width: 33%;
        height: 1px;
        background: #E0E0E0;
        position: absolute;
        left: 0;
        top: 11px;
    }
}