.responsive-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 1300px; /* Set your maximum width as needed */
    padding: 0 20px; /* Add padding to the sides if necessary */
  }

  // dashboard responsive
  @media screen and (max-width:991px){
    .main-content{
      width: 100% !important;
    }
    .sidebar-container{
      transition: all .5s ease-in-out;
      z-index: 2;
      top: 81px;
      height: calc(100vh - 81px) !important;
      bottom: 0;
      background: #fff !important;
      box-shadow: 0px 0px 15px rgb(189, 189, 189);
    }
    .mobile-hide-menu{
      transform: translateX(-100%);
    }
    .mobile-show-menu{
      transform: translateX(0%);
    }
    .top-header{
      background: linear-gradient(360deg, #C9BDE7 28.33%, #AAB073 88.85%);
      position: fixed;
      width: 100%;
      top: 0;
    }
    .dashboard-detail-container{
      margin-top: 80px;
    }
  }