.text-main{
    color: $main-color;
}
.heading-color{
    color: $heading-color;
}
.green-color{
    color: $green-color;
}
.subheading-color{
    color: $subHeading-color;
}